import { SafeAreaView } from "react-native-safe-area-context";
import { customColorTokens } from "../../../../tamagui.config";
import { Heading, View } from "tamagui";
import { HeaderButton } from "../../../components/design-system/Button/HeaderButton";
import { useNavigation } from "@react-navigation/native";
import { CloseIcon } from "../../../components/design-system/icons";

export const FriendsAndRequests = () => {
  const navigation = useNavigation();
  return (
    <SafeAreaView
      edges={["top"]}
      style={{ backgroundColor: customColorTokens.brandParchment, flex: 1 }}
    >
      <View px="$4" justifyContent="flex-start" alignItems="flex-start">
        <HeaderButton onPress={() => navigation.goBack()}>
          <CloseIcon width={16} height={16} />
        </HeaderButton>
      </View>
      <View flex={1} px="$4">
        <View py="$4" justifyContent="center">
          <Heading size="$3" textTransform="uppercase">
            Friends and Requests
          </Heading>
        </View>
        <View flex={1} pos="relative">
          {/* <Suspense fallback={<Loader />}>
          {data ? (
            <MembersList
              fragmentRef={data}
              refreshing={refreshing}
              refreshMembers={refreshMembers}
            />
          ) : null}
        </Suspense> */}
        </View>
      </View>
    </SafeAreaView>
  );
};
