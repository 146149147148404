import { Heading, View, XStack } from "tamagui";
import { useNavigation } from "@react-navigation/native";
import { Button, Input } from "../../../components/design-system";
import { useSyncStravaRoutes } from "../../../features/Strava/hooks/useSyncStravaRoutes";
import { RouteListQueryString, RoutesList } from "./components/RoutesList";
import { ArrowLeftIcon } from "../../../components/design-system/icons";
import { HeaderButton } from "../../../components/design-system/Button/HeaderButton";
import { SafeAreaView } from "react-native-safe-area-context";
import { customColorTokens } from "../../../../tamagui.config";
import { useQueryLoader } from "react-relay";
import { RoutesListQuery } from "./components/__generated__/RoutesListQuery.graphql";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../../atoms/global";
import { useEffect, useMemo, useState } from "react";
import { RefreshCcw } from "@tamagui/lucide-icons";
import { debounce } from "lodash";

export const Route = () => {
  const [searchTerm, setQuery] = useState("");
  const navigation = useNavigation();

  const setDebounceSearchTerm = debounce(setQuery, 500);

  const [queryRef, loadQuery] =
    useQueryLoader<RoutesListQuery>(RouteListQueryString);

  const user = useAtomValue(currentUserAtom);

  const iLikeReadySearchTerm = useMemo(() => `%${searchTerm}%`, [searchTerm]);

  useEffect(() => {
    if (user?.id && iLikeReadySearchTerm) {
      console.log(iLikeReadySearchTerm, user.id);
      loadQuery({ profileId: user.id, searchTerm: iLikeReadySearchTerm });
    }
  }, [user, loadQuery, iLikeReadySearchTerm]);

  const refreshQuery = () => {
    if (user?.id) {
      loadQuery(
        { profileId: user.id, searchTerm: iLikeReadySearchTerm },
        { fetchPolicy: "network-only" }
      );
    }
  };

  const [syncStravaRoutes, isSyncing] = useSyncStravaRoutes(refreshQuery);

  return (
    <SafeAreaView
      style={{ backgroundColor: customColorTokens.brandParchment, flex: 1 }}
    >
      <View flex={1}>
        <View
          px="$4"
          pb="$4"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <XStack
            justifyContent="space-between"
            w="100%"
            alignItems="flex-start"
          >
            <View>
              <HeaderButton onPress={() => navigation.goBack()}>
                <ArrowLeftIcon color="black" width={16} height={16} />
              </HeaderButton>
            </View>
            <View justifyContent="flex-end" alignItems="flex-end" gap={4}>
              <HeaderButton onPress={() => syncStravaRoutes()}>
                <RefreshCcw color="black" width={16} height={16} />
              </HeaderButton>
            </View>
          </XStack>
        </View>
        <Heading size="$4" px="$4">
          Select a Route
        </Heading>

        <View p="$4">
          <Input
            placeholder="Search Routes"
            onChangeText={setDebounceSearchTerm}
          />
        </View>

        <RoutesList
          queryRef={queryRef}
          isSyncing={isSyncing}
          onRefresh={refreshQuery}
        />
      </View>
    </SafeAreaView>
  );
};
