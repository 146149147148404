import { useCallback, useEffect, useState } from "react";
import { HeaderButton } from "../design-system/Button/HeaderButton";
import { UserMinus2, UserPlus2 } from "@tamagui/lucide-icons";
import { ArrowPathIcon } from "react-native-heroicons/solid";
import { supabase } from "../../../lib/supabase";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../../../atoms/global";
import { useFriendRequestUser } from "../../hooks/useFriendRequestUser";
import { useIsFocused } from "@react-navigation/native";
import { useRemoveFriendRequest } from "../../hooks/useRemoveFriendRequest";
import * as Burnt from "burnt";
import { fr } from "date-fns/locale";
import { useAcceptFriendRequest } from "../../hooks/useAcceptFriendRequest";

type Props = {
  userId: string;
};

type FriendshipStatus = "pending" | "accepted" | null;

export type Friendship = {
  id: string;
  user_id: string;
  friend_id: string;
  status: FriendshipStatus;
};

export const FriendshipButton = ({ userId }: Props) => {
  const currentUser = useAtomValue(currentUserAtom);
  const currentUserId = currentUser?.id;

  const [friendShip, setFriendship] = useState<Friendship | null>(null);

  const [loading, setLoading] = useState(false);

  const fetchFriendship = useCallback(async () => {
    setLoading(true);
    console.log(userId, currentUserId);
    console.log(currentUser?.id);
    supabase
      .from("friendships")
      .select("id, user_id, friend_id, status")
      .or(`user_id.eq.${currentUserId},user_id.eq.${userId}`)
      .or(`friend_id.eq.${currentUserId},friend_id.eq.${userId}`)

      .single()
      .then(({ data }) => {
        console.log("data", data);
        if (data) {
          setFriendship(data);
        }
        setLoading(false);
      });
  }, [currentUserId, userId, setFriendship, setLoading]);

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      fetchFriendship();
    }
  }, [isFocused, fetchFriendship]);

  console.log(friendShip);

  const [sendFriendRequest, isSendingFollowMutation] = useFriendRequestUser();
  const [unFriendUser, isUnfriendingUser] = useRemoveFriendRequest();
  const [accpetFriendRequest, isaAcceptingFriendRequest] =
    useAcceptFriendRequest();

  const handlePress = () => {
    if (friendShip?.status === "accepted") {
      unFriendUser(friendShip.id, () => {
        setFriendship(null);
      });
    } else if (!!friendShip?.user_id && friendShip?.user_id !== currentUserId) {
      accpetFriendRequest(friendShip.id, (friendship) => {
        setFriendship(friendship);
        Burnt.toast({
          title: "Friend Request Accepted",
          haptic: "success",
        });
      });
    } else {
      sendFriendRequest(userId, (friendship) => {
        Burnt.toast({
          title: "Friend request sent",
          haptic: "success",
        });

        setFriendship(friendship);
      });
    }
  };

  const isLoading = loading || isUnfriendingUser || isSendingFollowMutation;

  const friendshipStatus = friendShip?.status;

  return (
    <HeaderButton onPress={handlePress} disabled={isLoading}>
      {isLoading ? (
        <ArrowPathIcon color="black" width={16} height={16} />
      ) : friendshipStatus === "accepted" ||
        (friendShip?.user_id === currentUser?.id &&
          friendshipStatus === "pending") ? (
        <UserMinus2 color="black" width={16} height={16} />
      ) : (
        <UserPlus2 color="black" width={16} height={16} />
      )}
    </HeaderButton>
  );
};
